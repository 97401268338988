import PropTypes from "prop-types"
import React from "react"
import {Transition} from "semantic-ui-react"
import Alert from "../Alert/alert"
import "./notifications.scss"

/**
 * Display app notifications
 */
const Notifications = ({displayNotifications, notifications = [], onDismiss}) => {
    return (
        <div className="notifications-component">
            <Transition.Group animation="fade down" duration={500}>
                {displayNotifications &&
                    notifications.map(notification => (
                        <Alert
                            className="notification-component"
                            key={"notification-" + notification.id}
                            onDismiss={() => onDismiss(notification)}
                            {...notification}
                        />
                    ))}
            </Transition.Group>
        </div>
    )
}

Notifications.defaultProps = {
    notifications: [],
    onDismiss: () => "",
}

Notifications.propTypes = {
    /** True to display notifications, false to hide them. */
    displayNotifications: PropTypes.bool.isRequired,
    /**
     * An array of notifications.
     */
    notifications: PropTypes.array,
    /**
     * Function called when clicking on the "x" icon of a notification.
     */
    onDismiss: PropTypes.func,
}

export default Notifications
