import React from "react"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {Page} from "../constants/page/pages"
import history from "../history"
import {SERVIDOM_AREA} from "../shared-components/constants/areaTypes"

const MissionStatementScene = React.lazy(() =>
    import("../scenes/MissionStatement/missionStatementContainer").catch(() => window.location.reload()),
)

export const AsyncMissionStatementScene = () => <MissionStatementScene />

const NotFoundScene = React.lazy(() =>
    import("../shared-components/scenes/NotFound/notFoundContainer").catch(() => window.location.reload()),
)

export const AsyncNotFoundScene = () => (
    <NotFoundScene notFoundPage={Page.NOT_FOUND} onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)} />
)

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene areaType={SERVIDOM_AREA} legalNoticePage={Page.LEGAL_NOTICE} withCGS={false} />
)
