/**
 * Default values for global style for sos
 * The structure is a JSON containing key/value pairs.
 */
const sosStyle = {
    primaryColor: "#d7488f",
    secondaryColor: "#00000",
    tertiaryColor: "#a1b1bc",
    successColor: "#c2009b",
    warningColor: "#ffd600",
    dangerColor: "#e84136",
}

export default sosStyle
