import PropTypes from "prop-types"
import React, {Component, Suspense} from "react"
import {connect} from "react-redux"
import {Route, Router, Switch, withRouter} from "react-router"
import {Grid} from "semantic-ui-react"
import Header from "../components/Header/header"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import CheckBrowserContainer from "../shared-components/components/CheckBrowser/checkBrowserContainer"
import CookiesNotificationContainer from "../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import Footer from "../shared-components/components/Footer/footer"
import NotificationsContainer from "../shared-components/components/Notifications/notificationsContainer"
import {DOMISERVE_CONTACT_URL} from "../shared-components/constants/links"
import {
    getCookiesChoices,
    getModalStep,
    isCookiesModalOpened,
    isCookiesUserAcceptanceExpired,
    isUserAcceptedCookies,
} from "../store/selectors/localStorage/cookiesSelectors"
import {getUserFullname, getUserPermissions, isUserConnected} from "../store/selectors/localStorage/userSelectors"
import {getNotifications} from "../store/selectors/notifications/notificationsSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import {AsyncLegalNoticeScene, AsyncMissionStatementScene, AsyncNotFoundScene} from "./asyncScenes"
import "./mainLayout.scss"
import {MatomoTrackPageView} from "./matomoTrackPageView"

class MainLayout extends Component {
    render() {
        const {history, onContactClick, onCookiesClick, onLegalClick} = this.props

        return (
            <Router history={history}>
                <div
                    className={
                        "main-layout" +
                        (process.env.NODE_ENV === "production" && !process.env.REACT_APP_PROD_MODE
                            ? " main-layout-bordered"
                            : "")
                    }
                >
                    <MatomoTrackPageView />
                    <div className="main-page">
                        <Header className="menu-container" />
                        <Grid centered className="notifications-container">
                            <Grid.Column only="computer" width={1} />
                            <Grid.Column width={14}>
                                <NotificationsContainer
                                    displayNotifications={this.props.actualPage !== Page.LOGIN}
                                    getNotifications={getNotifications}
                                />
                            </Grid.Column>
                            <Grid.Column only="computer" width={1} />
                        </Grid>
                        <CheckBrowserContainer userAgent={window.navigator.userAgent} />
                        <div className="main-content">
                            <Suspense fallback={<div />}>
                                <Switch>
                                    {/* Route /attestation/{token} to mission statement */}
                                    <Route
                                        component={AsyncMissionStatementScene}
                                        exact
                                        path={PagesArchitecture[Page.MISSION_STATEMENT].routeWithParamDesc}
                                    />

                                    {/* Legal notice */}
                                    <Route
                                        component={AsyncLegalNoticeScene}
                                        exact
                                        path={PagesArchitecture[Page.LEGAL_NOTICE].route}
                                    />

                                    <Route component={AsyncNotFoundScene} path="/" />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                    <CookiesNotificationContainer
                        getCookiesChoices={getCookiesChoices}
                        getModalStep={getModalStep}
                        isCookiesModalOpened={isCookiesModalOpened}
                        isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                        isUserAcceptedCookies={isUserAcceptedCookies}
                    />
                    <Footer
                        onContactClick={onContactClick}
                        onCookiesClick={onCookiesClick}
                        onLegalClick={onLegalClick}
                    />
                </div>
            </Router>
        )
    }
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    onCookiesClick: PropTypes.func.isRequired,
    onContactClick: PropTypes.func.isRequired,
    onLegalClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    let actualPage = getActualPage(state)
    return {
        actualPage: actualPage,
        isUserConnected: isUserConnected(state) && actualPage !== Page.BYPASS_LOGIN,
        username: getUserFullname(state),
        userPermissions: getUserPermissions(state),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // Display cookies modal
    onCookiesClick: () => dispatch(saveCookiesChoices({isModalOpened: true, step: "CONFIGURATION"})),

    // Go to the contact page.
    onContactClick: () => window.open(DOMISERVE_CONTACT_URL),

    // Go to the legal mentions page.
    onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
