import {Page} from "./pages"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 * - permissions: User permissions needed to access the page
 * - featureFlag : Tell if a page is subject to feature flagging by feature name
 */
export const PagesArchitecture = {
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/",
        userConnected: true,
    },
    [Page.CONTACT]: {
        breadcrumbText: "Contact",
        route: "/contact",
    },
    [Page.LEGAL_NOTICE]: {
        parentDisconnect: Page.LOGIN,
        breadcrumbText: "Mentions Légales",
        route: "/mentions-legales",
        parent: Page.HOME,
    },
    [Page.MISSION_STATEMENT]: {
        routeWithParamDesc: "/attestation/:token?",
    },
    [Page.NOT_FOUND]: {
        breadcrumbText: "Page introuvable",
        route: "/notfound",
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
    },
}
