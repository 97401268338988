import {combineReducers} from "redux"
import menu, * as fromMenu from "../../shared-components/store/temporaries/menu/menuReducer"
import page, * as fromPage from "../../shared-components/store/temporaries/page/pageReducer"
import table, * as fromTable from "../../shared-components/store/temporaries/table/tableReducer"
import stepBar, * as fromStepBar from "../../shared-components/store/temporaries/stepBar/stepBarReducer"
import pagination, * as fromPagination from "../../shared-components/store/temporaries/pagination/paginationReducer"
import search, * as fromSearch from "../../shared-components/store/temporaries/search/searchReducer"
import * as fromResetPassword from "../../shared-components/store/temporaries/resetPassword/resetPasswordReducer"
import tabBar, * as fromTabBar from "../../shared-components/store/temporaries/tabBar/tabBarReducer"

/**
 * Part of the store to stock temporary data.
 */
const temporaries = combineReducers({
    menu,
    page,
    pagination,
    resetPassword: fromResetPassword.resetPassword(),
    search,
    stepBar,
    tabBar,
    table,
})

export default temporaries

// MENU OPERATIONS
/**
 * Verify is the menu is responsive
 */
export const isMenuResponsive = state => fromMenu.isResponsive(state.menu)

// PAGE OPERATIONS
/**
 * Return the actual page.
 */
export const getActualPage = state => fromPage.getActualPage(state.page)
/**
 * Return the previous page.
 */
export const getPreviousPage = state => fromPage.getPreviousPage(state.page)

// TABLE OPERATIONS
/**
 * Return the table data (ids).
 * @param tableName the name of the table
 */
export const getTableDataIds = (state, tableName) => fromTable.getIds(state.table, tableName)
/**
 * Return the ids of the selected lines in a table.
 * @param tableName the name of the table
 */
export const getTableSelectedLinesIds = (state, tableName) => fromTable.getSelectedLines(state.table, tableName)
/**
 * Return the ids of the activated lines in a table.
 * @param tableName the name of the table
 */
export const getTableActivatedLinesIds = (state, tableName) => fromTable.getActivatedLines(state.table, tableName)

/**
 * Return if line ID is activated
 * @param tableName the name of the table
 * @param id the line id to test
 */
export const getTableIsLineIdActivated = (state, tableName, id) => fromTable.isLineActivated(state.table, tableName, id)

/**
 * Return the id of the line in edition in this table or null if there
 * is no line in edition.
 * @param tableName the name of the table
 */
export const getTableLineInEditionId = (state, tableName) => fromTable.getLineInEdition(state.table, tableName)

// PAGINATION OPERATIONS

/*
 * Return the active page for component
 */
export const getActivePage = (state, componentName) => fromPagination.getActivePage(state.pagination, componentName)
/**
 * Return the max page for a component
 */
export const getMaxPage = (state, componentName) => fromPagination.getMaxPage(state.pagination, componentName)

/**
 * Return the number of total results
 */
export const getTotalResults = (state, componentName) => fromPagination.getTotalResults(state.pagination, componentName)

// RESET PASSWORD OPERATIONS
/**
 * Tell if we should display a success message after resetting password
 */
export const getResetPasswordDisplaySuccessMessage = state =>
    fromResetPassword.getResetPasswordDisplaySuccessMessage(state.resetPassword)

export const getPasswordScore = state => fromResetPassword.getPasswordScore(state.resetPassword)

// STEP BAR OPERATIONS
/**
 * Return the actual step selected in the step bar.
 */
export const getStepBarActualStep = state => fromStepBar.getActualStep(state.stepBar)
/**
 * Return if the steps are clickable
 */
export const isStepsClickable = state => fromStepBar.isStepsClickable(state.stepBar)

// SEARCH OPERATIONS
/**
 * Return the results of the town search found in the state.
 */
export const getSearchTownResults = (state, name) => fromSearch.getTownResults(state.search, name)
/**
 * Return the query of the town search found in the state.
 */
export const getSearchTownQuery = (state, name) => fromSearch.getTownQuery(state.search, name)

// TAB BAR OPERATIONS
/**
 * Return the actual tab selected in the tab bar.
 */
export const getTabBarActualTab = state => fromTabBar.getActualTab(state.tabBar)
