import PropTypes from "prop-types"
import React from "react"
import {Message} from "semantic-ui-react"
import "./alert.scss"

/**
 * An alert based on https://react.semantic-ui.com/collections/message.
 */
const Alert = ({className, type, header, content, list, onDismiss}) => (
    <div className={!!className ? className + " alert-component" : "alert-component"}>
        {type === "success" && (
            <Message
                className="alert-component-message"
                content={content}
                floating
                header={header}
                list={list}
                onDismiss={onDismiss}
                positive
            />
        )}
        {type === "warning" && (
            <Message
                className="alert-component-message"
                content={content}
                floating
                header={header}
                list={list}
                onDismiss={onDismiss}
                visible
                warning
            />
        )}
        {type === "error" && (
            <Message
                className="alert-component-message"
                content={content}
                floating
                header={header}
                list={list}
                negative
                onDismiss={onDismiss}
            />
        )}
        {type === "info" && (
            <Message
                className="alert-component-message"
                content={content}
                floating
                header={header}
                info
                onDismiss={onDismiss}
            />
        )}
    </div>
)

Alert.defaultProps = {
    className: null,
    content: null,
    list: null,
    header: null,
    onDismiss: null,
}

Alert.propTypes = {
    /**
     * An additional className.
     */
    className: PropTypes.string,
    /**
     * Content, can be a string or some components.
     */
    content: PropTypes.any,
    /**
     * The header text.
     */
    header: PropTypes.string,
    /**
     * Array shorthand items for the MessageList. Mutually exclusive with content.
     */
    list: PropTypes.any,
    /**
     * A function called when clicking on the "x" icon.
     */
    onDismiss: PropTypes.func,
    /**
     * The type of Alert.
     */
    type: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
}

export default Alert
