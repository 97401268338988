import {combineReducers} from "redux"
import style, * as fromStyle from "../../shared-components/store/localStorage/style/styleReducer"
import texts, * as fromTexts from "../../shared-components/store/localStorage/texts/textsReducer"
import dynamicConfiguration, * as fromDynConf from "../../shared-components/store/localStorage/dynamicConfiguration/dynamicConfigurationReducer"
import user from "../../shared-components/store/sessionStorage/user/userReducer"
import cookies, * as fromCookies from "../../shared-components/store/localStorage/cookies/cookiesReducer"

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const localStorage = combineReducers({
    cookies,
    dynamicConfiguration,
    style,
    texts,
    user,
})

export default localStorage

// DYNAMIC CONFIGURATION OPERATIONS
/**
 * Return the value associated with the key if it is found in the state,
 * undefined otherwise.
 */
export const getDynamicConfigurationValue = (state, key) => fromDynConf.getValue(state.dynamicConfiguration, key)

// STYLE OPERATIONS
/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromStyle.getStyle(state.style, contractId)
/**
 * Is style customized ?
 */
export const isCustomizedStyle = (state, contractId) => fromStyle.isCustomized(state.style, contractId)

// TEXTS OPERATIONS
/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromTexts.getTexts(state.texts, contractId)
/**
 * Are texts customized ?
 */
export const areCustomizedTexts = (state, contractId) => fromTexts.areCustomized(state.texts, contractId)

// USER OPERATIONS

// COOKIES OPERATIONS
/**
 * Get cookies
 */
export const getCookiesChoices = state => fromCookies.getCookiesChoices(state.cookies)
