import {CommonRequestKeys} from "../../shared-components/common/constants/requests"
import {SosFasttEspaceAgencesRequestKeys} from "../../constants/requests"
import {requestsReducerFactory} from "../../shared-components/store/requests/requestsReducer"
import {combineReducers} from "redux"
import {keys} from "lodash"

const requestReducers = {}

keys(CommonRequestKeys).forEach(k => (requestReducers[k] = requestsReducerFactory(k)))
keys(SosFasttEspaceAgencesRequestKeys).forEach(k => (requestReducers[k] = requestsReducerFactory(k)))

const requests = combineReducers(requestReducers)

export default requests

// Selectors
export const isFetching = (requestName, state) => (state && state[requestName] ? state[requestName].isFetching : false)
export const getErrorMessage = (requestName, state) => state[requestName].errorMessage
export const getPayload = (requestName, state) => state[requestName].payload

export const getPayloadIds = (requestName, state) => {
    const payload = getPayload(requestName, state)
    const entityName = keys(state[requestName].payload)[0]

    return payload[entityName] && payload[entityName].result
}

export const getPayloadEntities = (requestName, state) => {
    const payload = getPayload(requestName, state)
    const entityName = keys(state[requestName].payload)[0]

    return payload[entityName] ? payload[entityName].entities[entityName] : []
}

export const getPayloadEntity = (requestName, state, id) =>
    state[requestName] && getPayloadEntities(requestName, state) ? getPayloadEntities(requestName, state)[id] : []
