import React from "react"
import PropTypes from "prop-types"
import {Grid} from "semantic-ui-react"
import logo from "../../img/logo.png"
import "./header.scss"
import {SOS_FASTT_AGENCIES_AREA} from "../../shared-components/constants/areaTypes"

/**
 * Normal menu, for large screens.
 */
const Header = (_, {sheet, texts}) => (
    <div className="header-component">
        <div className="header-top-component">
            <Grid centered className={sheet.classes.backgroundPrimaryColor + " header-top-inner"} columns="equal" />
        </div>
        <Grid centered className="header-component-grid">
            <Grid.Column only="computer" width={1} />
            <Grid.Column width={14}>
                <div className="header-component-inner">
                    <h1 className={sheet.classes.textPrimaryColor + " header-component-title"}>
                        {SOS_FASTT_AGENCIES_AREA}
                    </h1>
                    <div />
                    <div className="header-component-div">
                        <img alt="logo" className="header-component-div-logo" src={logo} />
                    </div>
                </div>
            </Grid.Column>
            <Grid.Column only="computer" width={1} />
        </Grid>
    </div>
)

Header.contextTypes = {
    sheet: PropTypes.object,
    texts: PropTypes.object,
}

export default Header
