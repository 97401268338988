/**
 * Global texts which are used in shared components or are commons to all applications.
 * Examples: placeholders or texts in shared components
 */

/**
 * Fields placeholder
 */
const placeHolderTexts = {
    birthdatePlaceholder: "Date de naissance * (JJ/MM/AAAA)",
    cesuAccountPlaceholder: "Compte CESU",
    cesuCodePlaceholder: "Code CESU",
    commentPlaceholder: "Commentaire",
    confirmEmailPlaceholder: "Confirmer votre Email",
    confirmNewPasswordPlaceholder: "Confirmation nouveau mot de passe",
    confirmPasswordPlaceholder: "Confirmation du nouveau mot de passe",
    oldPasswordPlaceholder: "Ancien mot de passe",
    emailPlaceholder: "Email",
    facialValuePlaceholder: "Valeur faciale",
    firstnamePlaceholder: "Prénom",
    interlocutorPlaceholder: "Interlocuteur",
    lastnamePlaceholder: "Nom",
    loginPlaceholder: "Identifiant",
    mainPhoneNumberPlaceholder: "Tél. Principal",
    newPasswordPlaceHolder: "Nouveau mot de passe",
    passwordPlaceholder: "Mot de passe",
    phoneNumberPlaceholder: "N° de téléphone",
    recipientPlaceholder: "Destinataire",
    statusPlaceholder: "Statut",
    secondaryPhoneNumberPlaceholder: "Tél. Secondaire",
    smsPlaceholder: "SMS",
    zoneAddress0Placeholder: "Destinataire",
    zoneAddress1Placeholder: "Complément d'adresse (ex : ZAC les Chemins)",
    zoneAddress2Placeholder: "Numéro, type et nom de voie (ex: 12 bis rue de lessard)",
    zoneAddress3Placeholder: "Lieu dit",
    postalCodeTownPlaceHolder: "Code postal, Ville *",

    simpleBirthdatePlaceholder: "Date de naissance",
    yesPlaceholder: "Oui",
    noPlaceholder: "Non",
}

export const sharedGlobalTexts = {
    ...placeHolderTexts,

    // COOKIES
    cookiesStepOneTitle: "Gérer mes cookies",
    cookiesStepOneParagraph:
        "Nous utilisons des cookies, y compris des cookies tiers, à des fins de fonctionnement, d'analyses statistiques, de personnalisation de votre expérience, " +
        "afin de vous proposer des contenus ciblés adaptés à vos centres d'intérêts. Pour en savoir plus, consultez notre politique de données personnelles, disponible à tout moment en bas de page du site. " +
        "Vous pouvez paramétrer, accepter ou refuser ces cookies dès maintenant.",
    cookiesStepTwoTitle: "Gestion de vos préférences sur les cookies",
    cookiesStepTwoParagraph1:
        "Sur ce site nous utilisons des outils de mesure d’audience pour comprendre le parcours de navigation des utilisateurs afin d’optimiser l’ergonomie et la mise en avant des contenus" +
        " les plus pertinents. ",
    cookiesStepTwoParagraph2:
        "Ces cookies ne sont déposés que si vous donnez votre accord. Vous pouvez ci-dessous vous informer " +
        "sur la nature de chacun des cookies déposés, les accepter ou les refuser. Ce choix est possible soit globalement pour l’ensemble du site et l’ensemble des services, soit service par service.",
    cookiesStepTwoParagraph3: "En savoir plus sur les cookies",
    cookiesStepTwoParagraph3Link: "https://www.cnil.fr/fr/cookies-et-traceurs-que-dit-la-loi",
    cookiesStepTwoSubtitle: "Préférence pour tous les services",
    cookiesStepTwoTitleParameter1: "Fonctionnement du site (Obligatoire)",
    cookiesStepTwoTitleParameter2: "Mesure d’audience",
    cookiesStepTwoTitle1Paragraph:
        "Ces cookies sont nécessaires pour assurer le fonctionnement optimal du site et ne peuvent être paramétrés. Ils nous permettent de vous offrir les principales fonctionnalités du site " +
        "(langue utilisée, résolution d'affichage, accessibilité, etc.).",
    cookiesStepTwoTitle2Paragraph:
        "Ces cookies permettent d’avoir une finalité strictement limitée à la seule mesure de l’audience du site ou de l’application (mesure des performances, " +
        "détection de problèmes de navigation, optimisation des performances techniques ou de son ergonomie, estimation de la puissance des serveurs nécessaires, analyse des contenus consulté), pour le compte exclusif de Domiserve.",
    cookiesStepTwoTitle2AtInternet: "Matomo",
    cookiesStepTwoTitle2AtInternetLinkName: "> Lien vers la politique de confidentialité",
    cookiesStepTwoTitle2AtInternetLink: "https://fr.matomo.org/privacy/",

    cookiesRefuseButton: "Refuser",
    cookiesAcceptButton: "Accepter",
    cookiesWithoutAccept: "Continuer sans accepter",
    cookiesAcceptAllButton: "Tout accepter",
    cookiesCustomLink: "Personnaliser",

    // DOMISERVE POSTAL ADDRESS
    domiserveAddress1: "DOMISERVE - BO",
    domiserveAddress2: "106, avenue Marx Dormoy",
    domiserveAddress3: "92120 MONTROUGE",

    // COMMON TEXTS
    mandatoryField: "* Champs obligatoires",
    noLabel: "Non",
    yesLabel: "Oui",
    orLabel: "ou",
    actions: "Actions",

    // COMPONENTS
    // MissionInformations
    missionInformationsWorkedDaysTypePlaceHolder: "Jours travaillés",
    missionInformationsWorkedDaysTypeWeek: "Semaine",
    missionInformationsWorkedDaysTypeWeekEnd: "Week-end",
    missionInformationsWorkedDaysTypePublicHoliday: "Jour férié",
    missionInformationsMissionStartDatePlaceHolder: "Date de début de mission",
    missionInformationsMissionEndDatePlaceHolder: "Date de fin de mission",
    missionInformationsMissionHourStartPlaceHolder: "Heure de début",
    missionInformationsMissionHourEndPlaceHolder: "Heure de fin",
    missionInformationsVariableHoursRadio: "L’intérimaire a-t-il des horaires variables ?",
    missionInformationsVariableHoursDropdownPlaceholder: "Type de sélection",
    missionInformationsVariableHoursDropdownDate: "Date(s)",
    missionInformationsVariableHoursDropdownWeekdays: "Jours de la semaine",
    missionInformationsVariableHoursDropdownWeekdaysMonday: "Lundi",
    missionInformationsVariableHoursDropdownWeekdaysTuesday: "Mardi",
    missionInformationsVariableHoursDropdownWeekdaysWednesday: "Mercredi",
    missionInformationsVariableHoursDropdownWeekdaysThursday: "Jeudi",
    missionInformationsVariableHoursDropdownWeekdaysFriday: "Vendredi",
    missionInformationsVariableHoursDropdownWeekdaysSaturday: "Samedi",
    missionInformationsVariableHoursDropdownWeekdaysSunday: "Dimanche",

    missionInformationsVariableHoursAddLine: "Ajouter une ligne",

    // TermsOfService
    termsOfServiceAcceptance: "J'accepte les ",
    termsOfServiceName: "Conditions Générales de Domiserve",
    termsOfServiceText: "Registration Overview Terms Of Service",
    termsOfServiceCGV: "Conditions Générales de Ventes",
    termsOfServiceCUS: "Conditions d'utilisation du service",
    termsOfServiceCGUS: "Conditions Générales d'Utilisation du Service",

    // PAGES
    // NOT FOUND PAGE
    notFoundText: "Page introuvable.",

    // ERROR PAGE
    technicalErrorText: "Il semblerait qu'une erreur technique se soit produite.",

    // PASSWORD POPUP
    passwordPopup: "Votre mot de passe doit valider les règles de sécurité listées ci-dessous.",

    // PASSWORD FIELD
    passwordFieldPasswordTypePopup: "Afficher le mot de passe",
    passwordFieldTextTypePopup: "Masquer le mot de passe",

    // PASSWORD RULES
    passwordRulesParagraphInfos:
        "Votre mot de passe doit respecter toutes les règles, une fois validées elles passeront en vert.",
    passwordRulesExpectedLength: "Le mot de passe doit contenir au moins 8 caractères",
    passwordRulesMatchingZxcvbnScore: "Le mot de passe ne doit pas être trop simple à deviner",
    passwordRulesMatchingExpectedAmountOfRules: "Le mot de passe remplit les critères de sécurité recommandés",
    passwordRulesUpperCase: "Le mot de passe doit contenir au moins 1 lettre majuscule",
    passwordRulesLowerCase: "Le mot de passe doit contenir au moins 1 lettre minuscule",
    passwordRulesDigit: "Le mot de passe doit contenir au moins 1 chiffre",
    passwordRulesSpecialCharacter: "Le mot de passe doit contenir au moins 1 caractère spécial",
    passwordRulesSamePassword: "Le mot de passe et le mot de passe de confirmation doivent être identiques",

    // UNAUTHORIZED PAGE
    unauthorizedText: "Oups, vous vous êtes perdu ?",

    // BUTTONS
    activateButton: "Activer",
    browseButton: "Parcourir",
    cancelButton: "Annuler",
    closeButton: "Fermer",
    copyButton: "Copier",
    createButton: "Créer",
    deactivateButton: "Désactiver",
    editButton: "Modifier",
    returnButton: "Retour",
    saveButton: "Enregistrer",
    searchButton: "Rechercher",

    // CARE LINES
    careLinesPrestationDatesLabel: "Date(s) de prestation",
    careLinePrestationStartDateLabel: "Heure de début",
    careLinePrestationEndDateLabel: "Heure de fin",
    careLineAddLineLabel: "Ajouter une ligne",

    // SELECT OPTIONS
    notSpecifiedOption: "Non renseigné",

    // RECOVER LOGIN PASSWORD PAGE
    recoverLoginPasswordHeader: "Email envoyé",
    recoverLoginPasswordSuccess: "Un email a été envoyé à l'adresse renseignée sur votre profil.",

    tableTotalResultText: totalResults =>
        !!totalResults && totalResults !== 0 ? `${totalResults} résultat(s).` : "Pas de résultat.",

    // RESET PASSWORD / LOGIN
    loginLabel: "Identifiant",
    oldPasswordLabel: "Ancien mot de passe *",
    validateButtonLabel: "Valider",
    resetPasswordValidateUpdate: "Valider le changement",
    resetPasswordInformationMessage: "Pour des raisons de sécurité, veuillez changer votre mot de passe.",
}
