/**
 * The pages of the application.
 */
export const Page = {
    BYPASS_LOGIN: "BYPASS_LOGIN",
    CONTACT: "CONTACT",
    MISSION_STATEMENT: "MISSION_STATEMENT",
    LEGAL_NOTICE: "LEGAL_NOTICE",
    LOGIN: "LOGIN",
    NOT_FOUND: "NOT_FOUND",
    RESET_PASSWORD: "RESET_PASSWORD",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    UNAUTHORIZED: "UNAUTHORIZED",
}
