import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"

export const defaultTexts = {
    // Add all shared global texts
    ...sharedGlobalTexts,

    missionStatementSubTitle: "Attestation de mission",
    missionStatementParagraph:
        "Un de vos intérimaires a fait une demande de Garde d'enfants. Pour qu'il puisse en bénéficier et se rendre en mission," +
        " merci de vérifier les informations préremplies sur la base des éléments fournis par l'intérimaire.",
    missionStatementDateLabel: "Date",
    missionStatementTempWorkerNumberLabel: "N° Intérimaire",
    missionStatementRequestNumberLabel: "N° Demande",
    missionStatementSocialReasonLabel: "Raison sociale",
    missionStatementPostalCodeTownLabel: "Code postal, Ville *",
    missionStatementAddressLabel1: "Adresse",
    missionStatementAddressComplementLabel: "Adresse complément",
    missionStatementAddressLabel2: "Adresse complément 1",
    missionStatementAddressLabel3: "Adresse complément 2",
    missionStatementPostalCodeLabel: "Code postal",
    missionStatementPhoneNumberLabel: "Téléphone",
    missionStatementInterlocutorLabel: "Interlocuteur *",
    missionStatementTownLabel: "Ville",
    missionStatementMailLabel: "Mail",
    missionStatementWorkContractParagraph: "Certifie établir un contrat de travail temporaire pour ",
    missionStatementNameLabel: "Nom",
    missionStatementFirstNameLabel: "Prénom",
    missionStatementMissionInformationsLocalizationTitle: "Lieu de mission",
    missionInformationsTitle: "Informations sur la mission",
    missionStatementMissionAddressLabel: "Adresse du lieu de mission *",
    missionStatementMissionStartDateLabel: "Date de début de mission",
    missionStatementMissionEndDateLabel: "Date de fin de mission",
    missionStatementMissionHourStartLabel: "Heure de début de mission",
    missionStatementMissionHourEndLabel: "Heure de fin de mission",
    missionStatementHoursRadio: "L'intérimaire a-t-il des horaires variables ?",
    missionStatementHoursRangesLabel: "Si oui, précisez les plages",
    missionStatementYesLabel: "Oui",
    missionStatementNoLabel: "Non",
    missionStatementConfirmationRadio: "Confirmez-vous l'exactitude des informations ci-dessus ?",
    missionStatementConfirmationParagraph:
        "Si vous cochez non, merci de modifier directement les informations préremplies.",
    missionStatementRefuseContent: "Êtes vous sûr de vouloir refuser cette attestation ? ",
    missionStatementRefuseCancelButton: "Annuler",
    missionStatementRefuseConfirmButton: "Oui, je suis sûr",

    missionStatementCancelButton: "Refuser",
    missionStatementSubmitButton: "Valider",

    missionStatementRefusingSuccess: "L'attestation à été refusée avec succès.",
    missionStatementValidationSuccess: "L'attestation à été validée avec succès.",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
