/* eslint-disable */
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, {Component} from "react"
import {connect} from "react-redux"
import {saveCookiesChoices} from "../../actions/localStorage/cookies/cookiesActions"
import CookiesNotification from "./cookiesNotification"

class CookiesNotificationContainer extends Component {
    static propTypes = {
        getCookiesChoices: PropTypes.func.isRequired,
        getModalStep: PropTypes.func.isRequired,
        isCookiesModalOpened: PropTypes.bool,
        isUserAcceptedCookies: PropTypes.bool,
    }

    componentDidMount() {
        if (this.props.isCookiesUserAcceptanceExpired) {
            this.props.saveCookiesChoices({step: "HOME", isModalOpened: true})
        } else if (this.props.isUserAcceptedCookies) {
            this.initialize()
        }
    }

    /**
     * Called when user click on acceptation button
     */
    onAcceptationClick = () => {
        this.props.saveCookiesChoices({
            step: "HOME",
            expirationDate: dayjs().add(6, "month"),
            enabled: true,
        })
        this.initialize()
    }

    /**
     * Called when user click on refusal button
     */
    onRefusalClick = () => {
        this.props.saveCookiesChoices({
            isModalOpened: false,
            step: "HOME",
            expirationDate: dayjs().add(6, "month"),
            enabled: false,
        })
    }

    /**
     * Called when user click on configuration button
     */
    onConfigurationClick = () => {
        this.props.saveCookiesChoices({step: "CONFIGURATION"})
    }

    /**
     * Initialize parameters
     */
    initialize = () => {
        this.props.saveCookiesChoices({
            isModalOpened: false,
        })
    }

    render() {
        return (
            <CookiesNotification
                cookiesAccepted={this.props.isUserAcceptedCookies}
                onAcceptationClick={this.onAcceptationClick}
                onConfigurationClick={this.onConfigurationClick}
                onRefusalClick={this.onRefusalClick}
                opened={this.props.isCookiesModalOpened}
                step={this.props.modalStep}
            />
        )
    }
}

const mapStateToProps = (state, props) => ({
    cookiesChoices: props.getCookiesChoices(state),
    isCookiesModalOpened: props.isCookiesModalOpened(state),
    isCookiesUserAcceptanceExpired: props.isCookiesUserAcceptanceExpired(state),
    isUserAcceptedCookies: props.isUserAcceptedCookies(state),
    modalStep: props.getModalStep(state),
})

const mapDispatchToProps = dispatch => ({
    saveCookiesChoices: values => dispatch(saveCookiesChoices(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CookiesNotificationContainer)
